//mobile
body{
	font-size: 1.4rem;
}
p img,figure img{@include max(w);}

.block{
	padding-top: 60px;margin-bottom: 60px;
	.title{
		padding-top: 20px;
		&:before{	height: 4px;}
		.en{font-size: 3.2rem;margin-bottom: 8px;}
		.jp{font-size: 1.3rem;}
		&.is-show:before{width: 40px;}
	}
	&.is-show{
		.title:before{width: 40px;}
	}
}

table{
	&:not(.nobreak){
		tr,th,td{display: block;border: none;padding: 0;width: 100%;}
		tr{border-bottom: 1px solid $BDC;padding-bottom: 20px;margin-bottom: 20px;}
		th{margin-bottom: 5px;}
	}
}

input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea{
	padding:4px 8px;font-size: 1.4rem;
}
form{
	#sei,#mei,#seikana,#meikana{margin:0;width: 46%;}
	#sei,#seikana{margin-right: 8%;}
	#comment textarea{height: 150px;}
	#submit{width: 100%;font-size: 1.8rem;padding: 20px 0;}
	#back{width: 180px;font-size: 1.6rem;padding: 15px 0;}
	.email-td .input-box{
		&:before,&:after{height: 28px;}
	}
}

#wrapper{
	&.headerfix{padding-top: 63px;}
}

header{
	padding: 15px 0;
	.logo{
		width: 100%;text-align: center;
		a{display: inline-block;}
		img{width: 140px;height: auto;margin: 0 auto;}
	}
}
#menu-btn{
	top:7px;right:7px;
}

.navi-wrap{
	width: 80%;right:-81%;
}
#gnavi{
	a{font-size: 1.4rem;}
}
footer{
	padding: 70px 0 15px;
	.foot-box{margin-bottom: 50px;}
	.foot-title{font-size: 1.4rem;padding-top: 120px;
		-webkit-background-size: 150px 96px;
	}
	.address{font-size: 1.2rem;}
	.share{margin-bottom: 50px;}
	.copyright{font-size: 1.0rem;}
}

.key{
	height: 450px;margin-bottom: 0;
	.image{width: 100%;height: 350px;}
	.key-title-box{
		top:auto;bottom:120px;
	}
	.key-title{
		font-size: 2.6rem;margin-bottom: 8px;
	}
	.key-message{
		font-size: 1.4rem;line-height: 1.4;
	}
}

@keyframes keyimganime{
	0%{opacity: 0;}
	100%{opacity: 1;}
}

.info{padding: 20px 6%;
	.title{font-size: 1.5rem;}
	.info-list{
		height: 35px !important;
		li{font-size: 1.0rem;}
	}
}

#about{
	.mission{
		padding: 80px 0 40px;margin-bottom: 60px;
		.subtitle{
			font-size: 2.1rem;margin-bottom: 40px;
		}
	}
	.logomark{
		.image{
			width: 260px;margin: 0 auto 15px;float: none;
			&:before{
				left:calc(50% - 130px);
			}
		}
		.subtitle{
			font-size: 2.1rem;
		}
	}
}

#services{
	.title{
		&:before{left:calc(50% - 20px);}
	}
	.subtitle{
		width: 100%;font-size: 1.6rem;
	}
	.service-box{
		background-position: 0 90px !important;
	}
}

#staff{margin-bottom: 80px;
	.title{
		&:before{left:calc(50% - 20px);}
	}
	.staff-box{
		display: block;padding: 0 6% 20px;
		&:before{width: 100%;}
	}
	.image-box{
		width: 100%;margin: 0 !important;
		.image{width: 50%;margin-bottom: 0;}
		.image-border{
			width: 50%;height: 0;padding-bottom: 76%;
		}
	}
	.post{padding-top: 45%;font-size: 1.2rem;}
	.subtitle{
		.en{font-size: 1.2rem;}
	}
	.profile{
		clear: both;padding-top: 0;font-size: 1.2rem;
		dt{width: 90px;}
		dd{margin-bottom: 5px;padding-left: 90px;}
	}
	.kawaguchi{
		&:before{left:-20%;}
		.image-box{
			.image{margin-right: 25px;float: left;}
			.image-border{top:-5px;left:-5px;}
		}
	}
	.noguchi{
			&:before{right:-20%;}
		.image-box{
			.image{margin-left: 25px;float: right;}
			.image-border{top:-5px;right:5px;left:auto;}
		}
	}
}

#company{
	.company-tb{
		th,td{padding: 0;}
		th{width: 100%;margin-bottom: 5px;}
	}
	.access{
		.subtitle{width: 80px;padding: 10px 5px;}
		p{padding-left: 90px;font-size: 1.3rem;}
	}
	.gmap-link{
		a{font-weight: normal;font-size: 1.3rem;}
	}
}

#contact{
	.title{
		&:before{left:calc(50% - 20px);}
	}
	.lead{text-align: left;}
	.contact-tb{font-size: 1.4rem;margin-bottom: 40px;
		th,td{padding: 0;}
		th{width: 100%;border: none;margin-bottom: 10px;}
		td{border: none;}
	}
	.require{float: none;margin: 3px 0 0 20px;font-size: 1.1rem;}
	.thanks-box{padding: 40px 15px;
		h4{font-size: 2.0rem;margin-bottom: 40px;}
		p{font-size: 1.3rem;letter-spacing: 0;}
	}
}

#privacy{
	padding-top: 10vh;
	.privacy-close{
		right:5%;top:calc(10% - 75px);
	}
	.privacy-wrapper{
		width: 90%;
	}
	.privacy-box{
		overflow-y: scroll;padding: 25px 5%;height: 80vh;
	}
	h4{font-size: 1.6rem;}
	h5{font-size: 1.4rem;}
	.box{
		padding: 4%;
	}
}
