//plugins

/*exValidation*/
.errMsg{margin-left:1em; padding-bottom:1px; display:block; line-height:1.4; text-indent:-.9em;}
.formError{
	display:block;margin-top: 5px;
	.formErrorContent{
		padding:4px 8px; width:auto; position:relative; color:$RED; background:transparent;font-size: 1.2rem; _z-index:1;
		border: 1px solid $RED;
	}
	.fadeOut{opacity: 0.2;}
}
.err{background-color:#ffecec !important;}


