//tablet
figure img,p img{
	@include max(w);
}

.spdisp{display: block;}
.sphide{display: none;}

#wrapper{
	min-width: 0;
}

#menu-btn{
	display: block;width: 50px;height: 50px;
	position: absolute;right:30px;top:28px;cursor: pointer;z-index: 999;
	.menu-line{display: block;width: 30px;height: 1px;
		background-color: $BDC;position: absolute;left:calc(50% - 15px);transition:background-color 0.3s $ease2;
		&:nth-child(1){top:16px;transition: top 0.2s $ease2 0.3s,transform 0.3s $ease2 0s;}
		&:nth-child(2){top:24px;transition: opacity 0.1s $ease2 0.2s;}
		&:nth-child(3){top:32px;transition: top 0.2s $ease2 0.3s,transform 0.3s $ease2 0s;}
	}
}
.navi-wrap{
	transition: right 0.5s $ease5;
	background: transparent;position: fixed;z-index: 998;top:0;right:-51%;width: 50%;
	&:before{
		content:"";display: block;width: 100%;height: 100vh;position: absolute;top:0;left:0;z-index: 0;
		background: linear-gradient(135deg,$GRAD_START,$GRAD_END);opacity: 0.9;
	}
}
#gnavi{
	position: relative;z-index: 998;	padding: 80px 8% 0;width: 100%;
	ul{display: block;}
	li{display: block;margin: 0;margin-bottom: 30px;}
	a{font-size: 1.6rem;color: #fff;line-height: 1.2;height: auto;}
	.head-contact a{
		background: none;padding: 0;
		&:before{display: none;}
		&:hover{
			box-shadow: none;
		}
	}
}

#menu.open{
	#menu-btn{
		.menu-line{
			background-color: #fff;
			&:nth-child(1){top:24px;transform: rotate(-135deg);transition: top 0.2s $ease2 0s,transform 0.3s $ease2 0.2s;}
			&:nth-child(2){opacity: 0;}
			&:nth-child(3){top:24px;transform: rotate(135deg);transition: top 0.2s $ease2 0s,transform 0.3s $ease2 0.2s;}
		}
	}
	.navi-wrap{right:0;}
}

header.fixed #menu-btn{
	top:10px;
}

footer{
	.foot-box{
		width: 100%;
	}
}



.key{height: 700px;margin-bottom: 100px;
	.image{width: 80%;}
	.line{background: #fff;}
	.key-title-box{left:6%;}
	.key-title{
		.liune{padding-right: 5px;}
	}
	.scroll{
		bottom:0;left:calc(10% - 15px);
	}
}
@keyframes keytitleanime{
	0%{left:0;opacity: 0;}
	100%{left:6%;opacity: 1;}
}
.info{
	margin-top: 0;margin-left: 0;padding: 25px 6%;display: block;border-top: 1px dotted #ccc;border-bottom: 1px dotted #ccc;margin-bottom: 40px;
	.title{
		border-right: none;border-bottom: 1px solid $BDC;width: auto;display: inline-block;padding-bottom: 5px;margin-bottom: 5px;
	}
	.info-list{
		width: 100%;padding: 0;
	}
}

#about{
	.title{
		width: 100%;padding-left: 6%;
		&:before{left:6%;}
	}
	.mission{
		.mission-box{
			width: 100%;padding: 0 6%;
		}
	}
	.logomark{
		width: 100%;padding: 0 6%;
	}
}

#services{
	.subtitle{margin-bottom: 25px;}
	.service-box{
		width: 100%;border-right: none !important;padding: 38px 6%;
		&:nth-child(5):after{display: none ;}
	}
}
#staff{
	.staff-box{
		&:before{width: 80%;}
	}
	.image-box{
		width: 200px;
		.image-border{width: 200px;height: 304px;}
		img{@include max(w);}
	}
	.subtitle{
		.en{display: block;}
	}
	.kawaguchi{
		padding:0 6% 0 3%;
		.image-box{margin-right: 25px;}
	}
	.noguchi{
		padding:0 3% 0 6%;
		.image-box{margin-left: 25px;}
	}
}

#company{
	.company-box{
		width: 100%;padding: 0 6%;margin-bottom: 0;
	}
	.title{
		position: static;width: 100%;
		&:before{left:6%;}
	}
	.company-tb{width: 100%;margin-bottom: 40px;}
	.access{
		position: static;padding: 25px 0;	width: 100%;border: none;
		.subtitle{
			width: 115px;float: left;border: 1px solid $BDC;padding: 10px;text-align: center;
		}
		p{padding-left: 135px;}
	}
	.gmap-link{
		right:auto;left:0;
	}
}

#contact{
	padding: 60px 6% 0;
	.lead{width: 100%;text-align: center;margin-bottom: 40px;}
	.contact-tb{width: 100%;
		th{width: 200px;}
	}
	.thanks-box{
		width: 100%;
	}
}
