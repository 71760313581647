@charset "utf-8";
@import "settings/vars";
@import "settings/mixins";
@import "base/reset";
@import "base/base";
@import "contents/form";
@import "contents/module";
@import "contents/layout";
@import "contents/page";
@import "contents/plugins";


//印刷
@media print {
	@import "print";
}

//small pc
@import "responsive/pc";


//tablet
@media only screen and (max-width: $BREAK_TAB){
	@import "responsive/tablet"; 
	#debug:before{content:"tb";}
}

//mobile
@media only screen and (max-width: $BREAK_SP){
	@import "responsive/sp"; 
	#debug:before{content:"sp";}
}