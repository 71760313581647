//base

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 900;
  src:
local('Noto Sans Japanese'),
    url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.otf) format('opentype');
}
@font-face {
  font-family: "YakuHanJPBold";
  font-weight: 400;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"),
  url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
  font-display: swap;
}

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');

@font-face {
  font-family: 'iconfont';
  src:
    url('../fonts/iconfont.ttf?42zols') format('truetype'),
    url('../fonts/iconfont.woff?42zols') format('woff'),
    url('../fonts/iconfont.svg?42zols#iconfont') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before,[class^="icon-"]:after, [class*=" icon-"]:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-blank:after {
  content: "\e902";
}
.icon-gmap:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\f082";
}
.icon-twitter:before {
  content: "\f099";
}


*{box-sizing:border-box; word-break: break-all;}
html{
	-webkit-text-size-adjust: none;
	font-size: 62.5%;
}

img{vertical-align: bottom;}
ol{padding-left:20px; list-style-type:decimal;}

strong{	font-weight: bold;}
sup,sub{height: 0; line-height: 1; vertical-align: baseline;  _vertical-align: bottom; position: relative; font-size:1rem;}
sup{bottom: 1ex;}
sub{top: .5ex;}

body{
	font-size:1.5rem;font-family:$FONT_FAMILY; color:$FONT_COLOR;line-height: $LINE_HEIGHT;letter-spacing: 0.05em;
}

.noto{font-family:YakuHanJPBold, 'Noto Sans Japanese', sans-serif;font-weight: 900;}
.roboto{font-family: Roboto;font-weight: 700;}
