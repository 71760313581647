@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: "";
  content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 900;
  src: local("Noto Sans Japanese"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjapanese/v6/NotoSansJP-Black.otf) format("opentype"); }

@font-face {
  font-family: "YakuHanJPBold";
  font-weight: 400;
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.0.0/dist/fonts/YakuHanJP/YakuHanJP-Bold.woff") format("woff");
  font-display: swap; }

@font-face {
  font-family: 'iconfont';
  src: url("../fonts/iconfont.ttf?42zols") format("truetype"), url("../fonts/iconfont.woff?42zols") format("woff"), url("../fonts/iconfont.svg?42zols#iconfont") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"]:before, [class*=" icon-"]:before, [class^="icon-"]:after, [class*=" icon-"]:after {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconfont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-blank:after {
  content: "\e902"; }

.icon-gmap:before {
  content: "\e901"; }

.icon-mail:before {
  content: "\e900"; }

.icon-facebook:before {
  content: "\f082"; }

.icon-twitter:before {
  content: "\f099"; }

* {
  box-sizing: border-box;
  word-break: break-all; }

html {
  -webkit-text-size-adjust: none;
  font-size: 62.5%; }

img {
  vertical-align: bottom; }

ol {
  padding-left: 20px;
  list-style-type: decimal; }

strong {
  font-weight: bold; }

sup, sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  _vertical-align: bottom;
  position: relative;
  font-size: 1rem; }

sup {
  bottom: 1ex; }

sub {
  top: .5ex; }

body {
  font-size: 1.5rem;
  font-family: YakuHanJP, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif;
  color: #555;
  line-height: 1.5;
  letter-spacing: 0.05em; }

.noto, .key .key-title {
  font-family: YakuHanJPBold, 'Noto Sans Japanese', sans-serif;
  font-weight: 900; }

.roboto, .block .title .en, footer .share .subtitle, #staff .subtitle .en {
  font-family: Roboto;
  font-weight: 700; }

input[type="button"], input[type="submit"] {
  -webkit-appearance: none;
  border: none;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  font-family: YakuHanJP, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif; }

input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea {
  -webkit-appearance: none;
  width: 100%;
  padding: 5px 12px;
  border: none;
  border-radius: 0;
  font-size: 1.6rem;
  background: transparent;
  font-family: YakuHanJP, -apple-system, BlinkMacSystemFont, "Helvetica Neue", "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "Hiragino Kaku Gothic ProN", メイリオ, Meiryo, sans-serif; }

:placeholder-shown {
  color: #bbb; }

::-webkit-input-placeholder {
  color: #bbb; }

::-moz-placeholder {
  color: #bbb;
  opacity: 1; }

:-ms-input-placeholder {
  color: #bbb; }

form #sei, form #mei, form #seikana, form #meikana {
  display: inline-block;
  width: 150px;
  margin-right: 25px; }

form #tel, form #fax {
  width: 280px; }

form #comment textarea {
  height: 300px; }

form .input-box {
  position: relative; }
  form .input-box:before, form .input-box:after {
    content: "";
    display: block;
    width: 6px;
    height: 100%;
    border: 1px solid #ccc;
    position: absolute;
    top: -1px; }
  form .input-box:before {
    border-right: none;
    left: 0; }
  form .input-box:after {
    border-left: none;
    right: 0; }

form .email-td .input-box:before, form .email-td .input-box:after {
  height: 34px; }

form .email-td .input-box:first-child {
  margin-bottom: 18px; }

form #submit {
  display: block;
  font-size: 2.1rem;
  color: #fff;
  width: 425px;
  margin: 0 auto 30px;
  padding: 25px 0;
  line-height: 1;
  background: linear-gradient(90deg, #009fe8 0%, #9404d7 50%, #009fe8 100%);
  transition: all 0.5s cubic-bezier(0.25, 0.48, 0.63, 0.93);
  background-size: 200% 100%;
  background-position: 0 0;
  font-weight: bold; }
  form #submit:hover {
    background-position: 100% 0;
    box-shadow: 0 0 30px rgba(145, 7, 215, 0.4); }

form #back {
  display: block;
  font-size: 1.8rem;
  color: #fff;
  width: 200px;
  margin: 0 auto;
  padding: 20px 0;
  background: #666;
  line-height: 1;
  transition: all 0.5s cubic-bezier(0.25, 0.48, 0.63, 0.93);
  font-weight: bold; }
  form #back:hover {
    opacity: 0.6; }

a {
  color: #009fe8;
  text-decoration: none;
  transition: all 0.3s cubic-bezier(0.25, 0.48, 0.63, 0.93); }
  a:hover {
    text-decoration: underline; }
    a:hover img {
      opacity: 0.6; }

.cf:after {
  content: "";
  display: block;
  clear: both; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.left {
  text-align: left; }

.fl_left {
  float: left;
  width: auto; }

.fl_right {
  float: right;
  width: auto; }

.cl {
  clear: both; }

.l {
  font-size: 1.8rem; }

.s {
  font-size: 1.4rem; }

.xs {
  font-size: 1.2rem; }

.red {
  color: #e80202; }

.box {
  padding: 25px 30px;
  margin-bottom: 30px;
  border: 1px solid #3e3a39; }
  .box > *:last-child {
    margin-bottom: 0 !important; }

.spdisp {
  display: none; }

.anm {
  opacity: 0;
  position: relative;
  top: 20px;
  transition: opacity 1.2s cubic-bezier(0.25, 0.48, 0.63, 0.93) 0.3s, top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s; }
  .anm.is-show {
    opacity: 1;
    top: 0; }

.block {
  padding-top: 80px;
  margin-bottom: 80px; }
  .block .title {
    color: #151515;
    margin-bottom: 45px;
    padding-top: 30px;
    position: relative; }
    .block .title:before {
      content: "";
      width: 0;
      height: 6px;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, #009fe8, #9404d7); }
    .block .title .en {
      display: block;
      font-size: 4.8rem;
      line-height: 1;
      letter-spacing: 0.05em;
      margin-bottom: 12px; }
    .block .title .jp {
      display: block;
      font-size: 1.6rem;
      line-height: 1;
      font-weight: bold; }
  .block.is-show .title:before {
    transition: width 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
    width: 60px; }
  .block .title.is-show:before {
    transition: width 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s;
    width: 60px; }
  .block p, .block ul, .block ol, .block dl {
    margin-bottom: 30px; }

.breadcrumb {
  font-size: 1rem; }
  .breadcrumb li {
    display: inline-block; }
    .breadcrumb li:before {
      content: ">";
      display: inline-block;
      margin: 0 5px 0 2px;
      color: #3e3a39; }
    .breadcrumb li:first-child:before {
      display: none; }

table {
  width: 100%; }

hr {
  border: none;
  border-bottom: 5px double #3e3a39;
  padding-top: 80px;
  margin-bottom: 80px; }

#wrapper {
  width: 100%;
  min-width: 1000px; }
  #wrapper.headerfix {
    padding-top: 109px; }

header {
  width: 100%;
  padding: 28px 40px;
  position: relative; }
  header .logo {
    width: 227px; }
    header .logo a {
      display: block; }

#menu-btn {
  display: none; }

#gnavi {
  width: calc(100% - 267px);
  position: absolute;
  right: 0;
  top: 38px; }
  #gnavi ul {
    display: flex;
    justify-content: flex-end; }
  #gnavi li {
    display: inline-block;
    margin: 0 24px; }
  #gnavi a {
    height: 36px;
    line-height: 36px;
    font-weight: bold;
    display: block;
    color: #151515;
    text-decoration: none;
    overflow: hidden;
    position: relative; }
    #gnavi a:after {
      content: "";
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, #009fe8, #9404d7);
      position: absolute;
      left: -100%;
      bottom: 0;
      transition: left 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
    #gnavi a:hover:after {
      left: 0; }
  #gnavi .head-contact {
    margin-right: 0; }
    #gnavi .head-contact a {
      display: block;
      color: #fff;
      padding: 0 20px;
      background: linear-gradient(90deg, #009fe8 0%, #9404d7 50%, #009fe8 100%);
      transition: all 0.5s cubic-bezier(0.25, 0.48, 0.63, 0.93);
      background-size: 200% 100%;
      background-position: 0 0; }
      #gnavi .head-contact a:hover {
        background-position: 100% 0;
        box-shadow: 0 0 15px rgba(145, 7, 215, 0.4); }
      #gnavi .head-contact a:before {
        font-size: 1.7rem;
        margin-right: 5px; }

header.fixed {
  position: fixed;
  top: 0px;
  left: 0;
  padding: 10px 40px;
  z-index: 999;
  width: 100%;
  background: #fff;
  animation: headeranime 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s 1 normal; }
  header.fixed #gnavi {
    top: 20px; }

@keyframes headeranime {
  0% {
    top: -80px; }
  100% {
    top: 0; } }

footer {
  background: url(../images/bg_footer.jpg) center center no-repeat;
  background-size: cover;
  padding: 105px 0 28px;
  color: #fff;
  text-align: center; }
  footer .foot-box {
    width: 960px;
    margin: 0 auto 75px; }
  footer .foot-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 30px;
    padding-top: 150px;
    background: url(../images/foot_logo.png) center 0 no-repeat;
    background-size: 185px 118px; }
  footer .address {
    font-size: 1.4rem;
    line-height: 1.7; }
  footer .share {
    margin-bottom: 90px; }
    footer .share ul {
      display: flex;
      justify-content: space-between;
      width: 185px;
      margin: -30px auto 0; }
    footer .share li {
      display: block;
      width: 40px; }
    footer .share a {
      display: block;
      width: 40px;
      height: 40px;
      overflow: hidden;
      position: relative;
      text-indent: -9999px;
      color: #fff; }
      footer .share a:before {
        position: absolute;
        text-indent: 0;
        top: 5px;
        left: 5px;
        font-size: 3.2rem;
        transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
      footer .share a:hover:before {
        transform: scale(1.2);
        opacity: 0.7; }
  footer .copyright {
    font-family: Roboto;
    font-weight: 400;
    font-size: 1.2rem;
    opacity: 0.7; }

.key {
  position: relative;
  height: 768px;
  overflow: hidden; }
  .key .key-title-box {
    position: absolute;
    z-index: 2;
    left: 7%;
    top: 215px;
    animation: keytitleanime 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s 1 normal;
    animation-fill-mode: both; }
  .key .key-title {
    font-size: 5.5rem;
    line-height: 1.3;
    margin-bottom: 15px;
    color: #151515; }
  .key .key-message {
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 2.0;
    color: #3e3a39; }
  .key .image {
    position: absolute;
    right: 0;
    top: 0;
    width: 70%;
    height: 700px;
    overflow: hidden;
    z-index: 0;
    animation: keyimganime 1.5s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.5s 1 normal;
    animation-fill-mode: both; }
    .key .image img {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, 0);
      width: auto;
      min-width: auto;
      height: 100%; }
  .key .scroll {
    position: absolute;
    left: calc(15% - 15px);
    bottom: 0;
    width: 25px;
    height: 80px;
    overflow: hidden; }
    .key .scroll:before {
      content: "";
      width: 1px;
      height: 80px;
      position: absolute;
      left: 15px;
      top: -80px;
      background: linear-gradient(180deg, #009fe8, #9404d7);
      animation: scrollanime 2s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite normal; }

@keyframes keytitleanime {
  0% {
    left: 0;
    opacity: 0; }
  100% {
    left: 7%;
    opacity: 1; } }

@keyframes keyimganime {
  0% {
    right: -7%;
    opacity: 0; }
  100% {
    right: 0;
    opacity: 1; } }

@keyframes scrollanime {
  0% {
    top: -80px; }
  40% {
    top: 0px; }
  60% {
    top: 0px; }
  100% {
    top: 80px; } }

.info {
  margin-left: 30%;
  display: flex;
  padding-top: 15px;
  margin-bottom: 100px;
  margin-top: -68px; }
  .info .title {
    width: 98px;
    font-size: 1.8rem;
    color: #151515;
    line-height: 1;
    border-right: 1px solid #3e3a39; }
  .info .info-list {
    width: calc(100% - 98px);
    padding-left: 20px;
    position: relative;
    overflow: hidden; }
    .info .info-list ul {
      width: 100%;
      position: relative; }
    .info .info-list li {
      width: 100%;
      font-size: 1.4rem;
      display: none; }

#about .title {
  width: 1000px;
  margin: 0 auto 45px; }

#about .mission {
  width: 100%;
  padding: 130px 0;
  margin-bottom: 95px;
  background: url(../images/bg_mission.jpg) center center no-repeat;
  background-size: cover; }
  #about .mission .mission-box {
    width: 880px;
    margin: 0 auto;
    color: #fff; }
  #about .mission .subtitle {
    margin-bottom: 70px;
    padding-bottom: 16px;
    position: relative;
    text-align: center;
    font-size: 3.0rem;
    font-weight: bold; }
    #about .mission .subtitle:before {
      content: "";
      width: 70px;
      height: 1px;
      background: #fff;
      position: absolute;
      left: calc(50% - 35px);
      bottom: 0; }
  #about .mission p {
    line-height: 2.0; }

#about .logomark {
  width: 1000px;
  margin: 0 auto; }
  #about .logomark .image {
    float: left;
    width: 260px;
    height: 260px;
    margin: 0 36px 15px 0;
    background: url(../images/bg_logomark.png) no-repeat;
    background-size: cover;
    position: relative; }
    #about .logomark .image img {
      opacity: 0; }
    #about .logomark .image:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 260px;
      height: 260px;
      opacity: 0;
      opacity: 0;
      background: url(../images/img_logomark01.png) no-repeat;
      background-size: cover; }
  #about .logomark .subtitle {
    font-size: 2.4rem;
    font-weight: bold;
    color: #151515;
    margin-bottom: 20px; }
  #about .logomark p {
    line-height: 2.0; }
  #about .logomark.is-show .image img {
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 2.5s; }
  #about .logomark.is-show .image:before {
    animation: logolineanime 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1.5s 1 normal; }

@keyframes logolineanime {
  0% {
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

#services .title {
  text-align: center; }
  #services .title:before {
    left: calc(50% - 30px); }

#services .subtitle {
  font-size: 2.0rem;
  line-height: 1;
  color: #fff;
  width: 350px;
  background: #009fe8;
  margin: 0 auto 35px;
  padding: 8px 0;
  font-weight: bold;
  text-align: center; }

#services p {
  line-height: 2.0;
  margin-bottom: 0; }

#services .service-list {
  display: flex;
  flex-wrap: wrap; }

#services .service-box {
  width: 50%;
  padding: 38px 4%;
  position: relative;
  background-size: 150px !important; }
  #services .service-box:before {
    content: "";
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #009fe8, #9404d7);
    position: absolute;
    top: 0;
    left: 0; }
  #services .service-box:nth-child(2n+1) {
    border-right: 1px solid #9404d7; }
  #services .service-box:nth-child(2n) .subtitle {
    background: #6f328e; }
  #services .service-box:nth-child(5):after, #services .service-box:nth-child(6):after {
    content: "";
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, #009fe8, #9404d7);
    position: absolute;
    bottom: 0;
    left: 0; }
  #services .service-box:nth-child(1) {
    background: url(../images/num_service01.png) 0 0 no-repeat; }
  #services .service-box:nth-child(2) {
    background: url(../images/num_service02.png) 0 0 no-repeat; }
  #services .service-box:nth-child(3) {
    background: url(../images/num_service03.png) 0 0 no-repeat; }
  #services .service-box:nth-child(4) {
    background: url(../images/num_service04.png) 0 0 no-repeat; }
  #services .service-box:nth-child(5) {
    background: url(../images/num_service05.png) 0 0 no-repeat; }
  #services .service-box:nth-child(6) {
    background: url(../images/num_service06.png) 0 0 no-repeat; }

#staff {
  margin-bottom: 140px;
  overflow: hidden; }
  #staff .staff-box {
    width: 100%;
    position: relative;
    display: flex; }
    #staff .staff-box:before {
      content: "";
      width: 55%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 1; }
  #staff .title {
    text-align: center;
    margin-bottom: 110px; }
    #staff .title:before {
      left: calc(50% - 30px); }
  #staff .image-box {
    width: 250px;
    position: relative;
    z-index: 2;
    top: -40px; }
    #staff .image-box .image {
      margin-bottom: 12px; }
    #staff .image-box .image-border {
      width: 250px;
      height: 380px;
      position: absolute;
      z-index: 10;
      left: -10px;
      top: -10px;
      border-left: 1px solid #009fe8;
      border-right: 1px solid #9404d7; }
      #staff .image-box .image-border:before, #staff .image-box .image-border:after {
        content: "";
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, #009fe8, #9404d7);
        position: absolute;
        left: 0; }
      #staff .image-box .image-border:before {
        top: 0; }
      #staff .image-box .image-border:after {
        bottom: 0; }
  #staff .post {
    font-size: 1.3rem;
    font-weight: bold;
    color: #151515;
    margin-bottom: 10px;
    line-height: 1; }
  #staff .subtitle .en {
    font-style: italic;
    font-size: 1.3rem;
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 0; }
  #staff .subtitle .jp {
    font-size: 2.4rem;
    font-weight: bold;
    color: #151515;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px; }
  #staff .profile {
    font-size: 1.4rem;
    position: relative;
    z-index: 3;
    padding-top: 35px; }
    #staff .profile dt {
      width: 108px;
      float: left; }
    #staff .profile dd {
      padding-left: 108px;
      margin-bottom: 10px; }
  #staff .kawaguchi {
    padding-left: 100px;
    margin-bottom: 80px; }
    #staff .kawaguchi:before {
      left: -50px;
      transform: skew(-12deg);
      background: #e6f6fd; }
    #staff .kawaguchi .image-box {
      margin-right: 60px; }
    #staff .kawaguchi .subtitle .en {
      color: #009fe8; }
    #staff .kawaguchi.anm {
      left: -40px;
      top: 0;
      transition: opacity 1.2s cubic-bezier(0.25, 0.48, 0.63, 0.93) 0.5s, left 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s; }
      #staff .kawaguchi.anm.is-show {
        left: 0; }
  #staff .noguchi {
    padding-right: 100px;
    flex-direction: row-reverse; }
    #staff .noguchi:before {
      right: -50px;
      transform: skew(-12deg);
      background: #f1ebf4; }
    #staff .noguchi .image-box {
      margin-left: 60px; }
    #staff .noguchi .subtitle .en {
      color: #6f328e; }
    #staff .noguchi.anm {
      right: -40px;
      transition: opacity 1.2s cubic-bezier(0.25, 0.48, 0.63, 0.93) 0.5s, right 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s; }
      #staff .noguchi.anm.is-show {
        right: 0; }

#company .company-box {
  width: 1000px;
  margin: 0 auto 100px;
  position: relative; }

#company .title {
  width: 280px;
  position: absolute;
  right: 0;
  top: 0; }

#company .company-tb {
  font-size: 1.4rem;
  width: 620px; }
  #company .company-tb tr {
    border-bottom: 1px solid #3e3a39; }
    #company .company-tb tr:last-child {
      border-bottom: none; }
  #company .company-tb th, #company .company-tb td {
    vertical-align: top;
    padding: 24px 0; }
  #company .company-tb th {
    font-weight: bold;
    color: #151515;
    width: 115px; }

#company .access {
  width: 280px;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 1px solid #3e3a39;
  font-size: 1.4rem;
  padding: 15px; }
  #company .access h4 {
    font-weight: bold;
    color: #151515;
    margin-bottom: 5px; }
  #company .access p {
    margin-bottom: 0; }

#company .gmap-wrap {
  height: 500px;
  position: relative; }

#company #gmap {
  height: 100%;
  width: 100%; }

#company #infowin {
  margin-bottom: 0; }
  #company #infowin dt {
    font-weight: bold;
    color: #151515; }
  #company #infowin dd {
    font-size: 1.2rem; }

#company .gmap-link {
  display: inline-block;
  background: #fff;
  position: absolute;
  right: calc((100% - 1000px) / 2);
  bottom: 0; }
  #company .gmap-link a {
    display: block;
    color: #151515;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 12px 15px 12px 35px;
    position: relative;
    line-height: 1; }
    #company .gmap-link a:before {
      color: #009fe8;
      margin-right: 3px;
      position: absolute;
      left: 12px;
      top: 10px;
      font-size: 1.8rem;
      font-weight: normal; }
    #company .gmap-link a:hover {
      color: #009fe8;
      text-decoration: none; }

#contact {
  margin-bottom: 200px; }
  #contact .title {
    text-align: center; }
    #contact .title:before {
      left: calc(50% - 30px); }
  #contact .lead {
    text-align: center;
    margin: 0 auto 80px;
    line-height: 2.0; }
  #contact .contact-tb {
    font-size: 1.6rem;
    width: 840px;
    margin: 0 auto 80px; }
    #contact .contact-tb th, #contact .contact-tb td {
      vertical-align: top;
      padding: 35px 0 15px; }
    #contact .contact-tb th {
      font-weight: bold;
      color: #151515;
      width: 265px;
      border-bottom: 1px solid #3e3a39; }
    #contact .contact-tb td {
      border-bottom: 1px solid #ccc;
      border-left: 3px solid #fff; }
  #contact .require {
    float: right;
    font-size: 1.2rem;
    color: #e80202;
    display: inline-block;
    margin: 3px 10px 0 0; }
  #contact .btn-area {
    text-align: center; }
  #contact #privacy-link {
    display: inline-block;
    color: #009fe8;
    cursor: pointer; }
    #contact #privacy-link:hover {
      text-decoration: underline; }
  #contact .thanks-box {
    width: 600px;
    margin: 60px auto 0;
    padding: 60px 25px;
    text-align: center;
    border-top: none;
    border-bottom: none;
    border-left-color: #009fe8;
    border-right-color: #9404d7;
    position: relative; }
    #contact .thanks-box:before, #contact .thanks-box:after {
      content: "";
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, #009fe8, #9404d7);
      position: absolute;
      left: 0; }
    #contact .thanks-box:before {
      top: 0; }
    #contact .thanks-box:after {
      bottom: 0; }
    #contact .thanks-box h4 {
      font-size: 2.8rem;
      font-weight: bold;
      margin-bottom: 50px;
      color: #151515; }
    #contact .thanks-box p {
      line-height: 2; }

#privacy {
  opacity: 0;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
  padding-top: 15vh;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95); }
  #privacy.is-show {
    opacity: 1;
    visibility: visible; }
  #privacy .privacy-close {
    position: absolute;
    right: 10%;
    top: calc(15% - 75px); }
  #privacy .privacy-wrapper {
    background: #fff;
    width: 80%;
    margin: 0 auto;
    border-radius: 4px; }
  #privacy .privacy-box {
    overflow-y: scroll;
    padding: 45px 60px;
    height: 70vh; }
  #privacy h3 {
    font-size: 2.4rem;
    margin-bottom: 30px;
    color: #151515;
    font-weight: bold; }
  #privacy h4 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    padding-top: 15px;
    color: #151515;
    font-weight: bold; }
  #privacy h5 {
    font-size: 1.6rem;
    margin-bottom: 10px;
    font-weight: bold;
    color: #151515; }
  #privacy p, #privacy ul {
    margin-bottom: 20px;
    line-height: 1.7; }
  #privacy ul li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 20px; }
    #privacy ul li:before {
      content: "";
      width: 5px;
      height: 5px;
      border: 1px solid #1d2087;
      border-radius: 50%;
      position: absolute;
      top: 9px;
      left: 5px; }

/*exValidation*/
.errMsg {
  margin-left: 1em;
  padding-bottom: 1px;
  display: block;
  line-height: 1.4;
  text-indent: -.9em; }

.formError {
  display: block;
  margin-top: 5px; }
  .formError .formErrorContent {
    padding: 4px 8px;
    width: auto;
    position: relative;
    color: #e80202;
    background: transparent;
    font-size: 1.2rem;
    _z-index: 1;
    border: 1px solid #e80202; }
  .formError .fadeOut {
    opacity: 0.2; }

.err {
  background-color: #ffecec !important; }

@media screen and (max-width: 1200px) {
  #gnavi li {
    margin: 0 12px; }
  #gnavi .head-contact a {
    padding: 0 15px; } }

@media only screen and (max-width: 960px) {
  figure img, p img {
    width: 100%;
    min-width: 100%;
    height: auto; }
  .spdisp {
    display: block; }
  .sphide {
    display: none; }
  #wrapper {
    min-width: 0; }
  #menu-btn {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    right: 30px;
    top: 28px;
    cursor: pointer;
    z-index: 999; }
    #menu-btn .menu-line {
      display: block;
      width: 30px;
      height: 1px;
      background-color: #3e3a39;
      position: absolute;
      left: calc(50% - 15px);
      transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955); }
      #menu-btn .menu-line:nth-child(1) {
        top: 16px;
        transition: top 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.3s, transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s; }
      #menu-btn .menu-line:nth-child(2) {
        top: 24px;
        transition: opacity 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s; }
      #menu-btn .menu-line:nth-child(3) {
        top: 32px;
        transition: top 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.3s, transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s; }
  .navi-wrap {
    transition: right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background: transparent;
    position: fixed;
    z-index: 998;
    top: 0;
    right: -51%;
    width: 50%; }
    .navi-wrap:before {
      content: "";
      display: block;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      background: linear-gradient(135deg, #009fe8, #9404d7);
      opacity: 0.9; }
  #gnavi {
    position: relative;
    z-index: 998;
    padding: 80px 8% 0;
    width: 100%; }
    #gnavi ul {
      display: block; }
    #gnavi li {
      display: block;
      margin: 0;
      margin-bottom: 30px; }
    #gnavi a {
      font-size: 1.6rem;
      color: #fff;
      line-height: 1.2;
      height: auto; }
    #gnavi .head-contact a {
      background: none;
      padding: 0; }
      #gnavi .head-contact a:before {
        display: none; }
      #gnavi .head-contact a:hover {
        box-shadow: none; }
  #menu.open #menu-btn .menu-line {
    background-color: #fff; }
    #menu.open #menu-btn .menu-line:nth-child(1) {
      top: 24px;
      transform: rotate(-135deg);
      transition: top 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s; }
    #menu.open #menu-btn .menu-line:nth-child(2) {
      opacity: 0; }
    #menu.open #menu-btn .menu-line:nth-child(3) {
      top: 24px;
      transform: rotate(135deg);
      transition: top 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s; }
  #menu.open .navi-wrap {
    right: 0; }
  header.fixed #menu-btn {
    top: 10px; }
  footer .foot-box {
    width: 100%; }
  .key {
    height: 700px;
    margin-bottom: 100px; }
    .key .image {
      width: 80%; }
    .key .line {
      background: #fff; }
    .key .key-title-box {
      left: 6%; }
    .key .key-title .liune {
      padding-right: 5px; }
    .key .scroll {
      bottom: 0;
      left: calc(10% - 15px); }
  @keyframes keytitleanime {
    0% {
      left: 0;
      opacity: 0; }
    100% {
      left: 6%;
      opacity: 1; } }
  .info {
    margin-top: 0;
    margin-left: 0;
    padding: 25px 6%;
    display: block;
    border-top: 1px dotted #ccc;
    border-bottom: 1px dotted #ccc;
    margin-bottom: 40px; }
    .info .title {
      border-right: none;
      border-bottom: 1px solid #3e3a39;
      width: auto;
      display: inline-block;
      padding-bottom: 5px;
      margin-bottom: 5px; }
    .info .info-list {
      width: 100%;
      padding: 0; }
  #about .title {
    width: 100%;
    padding-left: 6%; }
    #about .title:before {
      left: 6%; }
  #about .mission .mission-box {
    width: 100%;
    padding: 0 6%; }
  #about .logomark {
    width: 100%;
    padding: 0 6%; }
  #services .subtitle {
    margin-bottom: 25px; }
  #services .service-box {
    width: 100%;
    border-right: none !important;
    padding: 38px 6%; }
    #services .service-box:nth-child(5):after {
      display: none; }
  #staff .staff-box:before {
    width: 80%; }
  #staff .image-box {
    width: 200px; }
    #staff .image-box .image-border {
      width: 200px;
      height: 304px; }
    #staff .image-box img {
      width: 100%;
      min-width: 100%;
      height: auto; }
  #staff .subtitle .en {
    display: block; }
  #staff .kawaguchi {
    padding: 0 6% 0 3%; }
    #staff .kawaguchi .image-box {
      margin-right: 25px; }
  #staff .noguchi {
    padding: 0 3% 0 6%; }
    #staff .noguchi .image-box {
      margin-left: 25px; }
  #company .company-box {
    width: 100%;
    padding: 0 6%;
    margin-bottom: 0; }
  #company .title {
    position: static;
    width: 100%; }
    #company .title:before {
      left: 6%; }
  #company .company-tb {
    width: 100%;
    margin-bottom: 40px; }
  #company .access {
    position: static;
    padding: 25px 0;
    width: 100%;
    border: none; }
    #company .access .subtitle {
      width: 115px;
      float: left;
      border: 1px solid #3e3a39;
      padding: 10px;
      text-align: center; }
    #company .access p {
      padding-left: 135px; }
  #company .gmap-link {
    right: auto;
    left: 0; }
  #contact {
    padding: 60px 6% 0; }
    #contact .lead {
      width: 100%;
      text-align: center;
      margin-bottom: 40px; }
    #contact .contact-tb {
      width: 100%; }
      #contact .contact-tb th {
        width: 200px; }
    #contact .thanks-box {
      width: 100%; }
  #debug:before {
    content: "tb"; } }

@media only screen and (max-width: 599px) {
  body {
    font-size: 1.4rem; }
  p img, figure img {
    width: 100%;
    min-width: 100%;
    height: auto; }
  .block {
    padding-top: 60px;
    margin-bottom: 60px; }
    .block .title {
      padding-top: 20px; }
      .block .title:before {
        height: 4px; }
      .block .title .en {
        font-size: 3.2rem;
        margin-bottom: 8px; }
      .block .title .jp {
        font-size: 1.3rem; }
      .block .title.is-show:before {
        width: 40px; }
    .block.is-show .title:before {
      width: 40px; }
  table:not(.nobreak) tr, table:not(.nobreak) th, table:not(.nobreak) td {
    display: block;
    border: none;
    padding: 0;
    width: 100%; }
  table:not(.nobreak) tr {
    border-bottom: 1px solid #3e3a39;
    padding-bottom: 20px;
    margin-bottom: 20px; }
  table:not(.nobreak) th {
    margin-bottom: 5px; }
  input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea {
    padding: 4px 8px;
    font-size: 1.4rem; }
  form #sei, form #mei, form #seikana, form #meikana {
    margin: 0;
    width: 46%; }
  form #sei, form #seikana {
    margin-right: 8%; }
  form #comment textarea {
    height: 150px; }
  form #submit {
    width: 100%;
    font-size: 1.8rem;
    padding: 20px 0; }
  form #back {
    width: 180px;
    font-size: 1.6rem;
    padding: 15px 0; }
  form .email-td .input-box:before, form .email-td .input-box:after {
    height: 28px; }
  #wrapper.headerfix {
    padding-top: 63px; }
  header {
    padding: 15px 0; }
    header .logo {
      width: 100%;
      text-align: center; }
      header .logo a {
        display: inline-block; }
      header .logo img {
        width: 140px;
        height: auto;
        margin: 0 auto; }
  #menu-btn {
    top: 7px;
    right: 7px; }
  .navi-wrap {
    width: 80%;
    right: -81%; }
  #gnavi a {
    font-size: 1.4rem; }
  footer {
    padding: 70px 0 15px; }
    footer .foot-box {
      margin-bottom: 50px; }
    footer .foot-title {
      font-size: 1.4rem;
      padding-top: 120px;
      -webkit-background-size: 150px 96px; }
    footer .address {
      font-size: 1.2rem; }
    footer .share {
      margin-bottom: 50px; }
    footer .copyright {
      font-size: 1.0rem; }
  .key {
    height: 450px;
    margin-bottom: 0; }
    .key .image {
      width: 100%;
      height: 350px; }
    .key .key-title-box {
      top: auto;
      bottom: 120px; }
    .key .key-title {
      font-size: 2.6rem;
      margin-bottom: 8px; }
    .key .key-message {
      font-size: 1.4rem;
      line-height: 1.4; }
  @keyframes keyimganime {
    0% {
      opacity: 0; }
    100% {
      opacity: 1; } }
  .info {
    padding: 20px 6%; }
    .info .title {
      font-size: 1.5rem; }
    .info .info-list {
      height: 35px !important; }
      .info .info-list li {
        font-size: 1.0rem; }
  #about .mission {
    padding: 80px 0 40px;
    margin-bottom: 60px; }
    #about .mission .subtitle {
      font-size: 2.1rem;
      margin-bottom: 40px; }
  #about .logomark .image {
    width: 260px;
    margin: 0 auto 15px;
    float: none; }
    #about .logomark .image:before {
      left: calc(50% - 130px); }
  #about .logomark .subtitle {
    font-size: 2.1rem; }
  #services .title:before {
    left: calc(50% - 20px); }
  #services .subtitle {
    width: 100%;
    font-size: 1.6rem; }
  #services .service-box {
    background-position: 0 90px !important; }
  #staff {
    margin-bottom: 80px; }
    #staff .title:before {
      left: calc(50% - 20px); }
    #staff .staff-box {
      display: block;
      padding: 0 6% 20px; }
      #staff .staff-box:before {
        width: 100%; }
    #staff .image-box {
      width: 100%;
      margin: 0 !important; }
      #staff .image-box .image {
        width: 50%;
        margin-bottom: 0; }
      #staff .image-box .image-border {
        width: 50%;
        height: 0;
        padding-bottom: 76%; }
    #staff .post {
      padding-top: 45%;
      font-size: 1.2rem; }
    #staff .subtitle .en {
      font-size: 1.2rem; }
    #staff .profile {
      clear: both;
      padding-top: 0;
      font-size: 1.2rem; }
      #staff .profile dt {
        width: 90px; }
      #staff .profile dd {
        margin-bottom: 5px;
        padding-left: 90px; }
    #staff .kawaguchi:before {
      left: -20%; }
    #staff .kawaguchi .image-box .image {
      margin-right: 25px;
      float: left; }
    #staff .kawaguchi .image-box .image-border {
      top: -5px;
      left: -5px; }
    #staff .noguchi:before {
      right: -20%; }
    #staff .noguchi .image-box .image {
      margin-left: 25px;
      float: right; }
    #staff .noguchi .image-box .image-border {
      top: -5px;
      right: 5px;
      left: auto; }
  #company .company-tb th, #company .company-tb td {
    padding: 0; }
  #company .company-tb th {
    width: 100%;
    margin-bottom: 5px; }
  #company .access .subtitle {
    width: 80px;
    padding: 10px 5px; }
  #company .access p {
    padding-left: 90px;
    font-size: 1.3rem; }
  #company .gmap-link a {
    font-weight: normal;
    font-size: 1.3rem; }
  #contact .title:before {
    left: calc(50% - 20px); }
  #contact .lead {
    text-align: left; }
  #contact .contact-tb {
    font-size: 1.4rem;
    margin-bottom: 40px; }
    #contact .contact-tb th, #contact .contact-tb td {
      padding: 0; }
    #contact .contact-tb th {
      width: 100%;
      border: none;
      margin-bottom: 10px; }
    #contact .contact-tb td {
      border: none; }
  #contact .require {
    float: none;
    margin: 3px 0 0 20px;
    font-size: 1.1rem; }
  #contact .thanks-box {
    padding: 40px 15px; }
    #contact .thanks-box h4 {
      font-size: 2.0rem;
      margin-bottom: 40px; }
    #contact .thanks-box p {
      font-size: 1.3rem;
      letter-spacing: 0; }
  #privacy {
    padding-top: 10vh; }
    #privacy .privacy-close {
      right: 5%;
      top: calc(10% - 75px); }
    #privacy .privacy-wrapper {
      width: 90%; }
    #privacy .privacy-box {
      overflow-y: scroll;
      padding: 25px 5%;
      height: 80vh; }
    #privacy h4 {
      font-size: 1.6rem; }
    #privacy h5 {
      font-size: 1.4rem; }
    #privacy .box {
      padding: 4%; }
  #debug:before {
    content: "sp"; } }
