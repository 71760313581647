//layout

// ラッパー
#wrapper{
	width: 100%;min-width:1000px;
	&.headerfix{padding-top: 109px;}
}

// ヘッダー
header{
	width: 100%;padding: 28px 40px;position: relative;
	.logo {
		width: 227px;
		a{display: block;}
	}
}

// グローバルナビ
#menu-btn{display: none;}

#gnavi{
	width: calc(100% - 267px);position: absolute;right:0;top:38px;
	ul{display: flex;justify-content: flex-end;}
	li{display: inline-block;margin: 0 24px;}
	a{height: 36px;line-height: 36px;font-weight: bold;display: block;color: $BLACK;
		text-decoration: none;overflow: hidden;position: relative;
		&:after{
			content:"";width: 100%;height: 1px;background: linear-gradient(90deg,$GRAD_START,$GRAD_END);
			position: absolute;left:-100%;bottom:0;transition: left 0.3s $ease1;
		}
		&:hover:after{
			left:0;
		}
	}
	.head-contact{
		margin-right: 0;
		a{display: block;color: #fff;padding: 0 20px;
			background: linear-gradient(90deg,$GRAD_START 0%,$GRAD_END 50%,$GRAD_START 100%);transition: all 0.5s $ease7;
			background-size:200% 100%;background-position: 0 0;
			&:hover{
				background-position: 100% 0;box-shadow: 0 0 15px rgba(145,7,215,0.4);
			}
			&:before{font-size: 1.7rem;margin-right: 5px;}
		}
	}
}

header.fixed{
	position: fixed;top:0px;left:0;padding: 10px 40px;z-index: 999;width: 100%;
	background: #fff;
	animation:headeranime 0.5s $ease1 0s 1 normal;
	#gnavi{top:20px;}
	
}
@keyframes headeranime{
	0%{top:-80px;}
	100%{top:0;}
}

article{
	
}


// フッター
footer{
	background: url(../images/bg_footer.jpg) center center no-repeat;background-size: cover;padding: 105px 0 28px;
	color: #fff;text-align: center;
	.foot-box{
		width: 960px;margin: 0 auto 75px;
	}
	.foot-title{
		font-size: 1.6rem;font-weight: bold;margin-bottom: 30px;padding-top: 150px;background: url(../images/foot_logo.png) center 0 no-repeat;
		background-size: 185px 118px;	
	}
	.address{
		font-size: 1.4rem;line-height: 1.7;
	}
	.share{margin-bottom: 90px;
		.subtitle{@extend .roboto;}
		ul{display: flex;justify-content: space-between;width: 185px;margin: -30px auto 0;}
		li{display: block;width: 40px;}
		a{display: block;width: 40px;height: 40px;overflow: hidden;position: relative;text-indent: -9999px;color: #fff;
			
			&:before{position: absolute;text-indent:0;top:5px;left:5px;font-size: 3.2rem;transition: all 0.2s $ease1;}
			&:hover:before{transform: scale(1.2);opacity: 0.7;}
		}
	}
	.copyright{
		font-family: Roboto;font-weight: 400;font-size: 1.2rem;opacity: 0.7;
	}
}
