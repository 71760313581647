//module
a{color:$BLUE;text-decoration: none;transition: all 0.3s $ease7;
	&:hover{text-decoration: underline;
		img{opacity:0.6; }
	}
}
// clearfix
.cf:after{content:"";display:block;	clear:both;}

.center {	text-align: center;}
.right {	text-align: right;}
.left {	text-align: left;}
.fl_left {	float: left;	width: auto;}
.fl_right {	float: right;	width: auto;}
.cl{clear:both;}

.arrow{

}

//フォントサイズ
.l{font-size:1.8rem;}
.s{font-size:1.4rem;}
.xs{font-size:1.2rem;}

//フォントカラー
.red{	color: $RED;}


.box{
	padding: 25px 30px;margin-bottom: 30px;border: 1px solid $BDC;
	>*:last-child{margin-bottom: 0 !important;}
}

.spdisp{display: none;}

.anm{opacity: 0;position:relative;top:20px;transition: opacity 1.2s $ease7 0.3s,top 1s $ease5 0.3s;
	&.is-show{opacity: 1;top:0;}
}

.block{
	padding-top: 80px;margin-bottom: 80px;
	.title{color: $BLACK;margin-bottom: 45px;padding-top: 30px;position: relative;
		&:before{
			content:"";width: 0;height: 6px;position: absolute;top:0;left:0;
			background: linear-gradient(90deg,$GRAD_START,$GRAD_END);
		}
		.en{display: block;@extend .roboto;font-size: 4.8rem;line-height: 1;letter-spacing: 0.05em;margin-bottom: 12px;}
		.jp{display: block;font-size: 1.6rem;line-height: 1;font-weight: bold;}
	}
	&.is-show{
		.title:before{transition: width 0.5s $ease1 0.5s;width: 60px;}
	}
	.title.is-show:before{transition: width 0.5s $ease1 0.5s;width: 60px;}
	p,ul,ol,dl{margin-bottom: 30px;}
}


//パンくずリスト
.breadcrumb{font-size:1rem;
	li{display: inline-block;
		&:before{content:">";display: inline-block;margin: 0 5px 0 2px;color: $BDC;}
		&:first-child:before{display: none;}
	}
}

//表組み
table{width: 100%;}

//罫線
hr{	border: none;border-bottom: 5px double $BDC;padding-top:80px;margin-bottom: 80px;}

