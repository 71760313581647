//page

//トップページ
#home{


}
.key{
	position: relative;height: 768px;overflow: hidden;
	.key-title-box{
		position: absolute;z-index: 2;left:7%;top:215px;
		animation: keytitleanime 1.5s $ease1 0.5s 1 normal;	animation-fill-mode: both;
	}
	
	.key-title{
		@extend .noto;font-size: 5.5rem;line-height: 1.3;margin-bottom: 15px;color:$BLACK;
	}
	.key-message{
		font-size: 1.8rem;font-weight: bold;line-height: 2.0;color: $BDC;
	}
	.image{
		position: absolute;right:0;top:0;width: 70%;height: 700px;overflow: hidden;z-index: 0;
		animation: keyimganime 1.5s $ease1 0.5s 1 normal;animation-fill-mode: both;
		img{position: absolute;left:50%;top:0;transform: translate(-50%,0);
			@include max(h);
		}
	}
	.scroll{
		position: absolute;left:calc(15% - 15px);bottom:0;width: 25px;height: 80px;overflow: hidden;
		&:before{content:"";width: 1px;height: 80px;position: absolute;left:15px;top:-80px;
			background: linear-gradient(180deg,$GRAD_START,$GRAD_END);
			animation: scrollanime 2s $ease1 0s infinite normal;
		}
	}
}

@keyframes keytitleanime{
	0%{left:0;opacity: 0;}
	100%{left:7%;opacity: 1;}
}
@keyframes keyimganime{
	0%{right:-7%;opacity: 0;}
	100%{right:0;opacity: 1;}
}

@keyframes scrollanime{
	0%{top:-80px;}
	40%{top:0px;}
	60%{top:0px;}
	100%{top:80px;}
}



.info{
	margin-left: 30%;display: flex;padding-top: 15px;margin-bottom: 100px;margin-top: -68px;
	.title{width: 98px;font-size: 1.8rem;color: $BLACK;line-height: 1;border-right: 1px solid $BDC;}
	.info-list{
		width: calc(100% - 98px);padding-left: 20px;position: relative;overflow: hidden;
		ul{width: 100%; position: relative;}
		li{width: 100%;font-size: 1.4rem; display: none;}
	}
}

#about{
	.title{
		width: 1000px;margin: 0 auto 45px;
	}
	.mission{
		width: 100%;padding: 130px 0;margin-bottom: 95px;
		background: url(../images/bg_mission.jpg) center center no-repeat;background-size: cover;
		.mission-box{width: 880px; margin: 0 auto;color: #fff;}
		.subtitle{margin-bottom: 70px;padding-bottom: 16px;position: relative;text-align: center;font-size: 3.0rem;font-weight: bold;
			&:before{
				content:"";width: 70px;height: 1px;background: #fff;position: absolute;left:calc(50% - 35px);bottom:0;
			}
		}
		p{line-height: 2.0;}
	}
	.logomark{
		width: 1000px;margin: 0 auto;
		.image{float: left;width: 260px;height: 260px;margin:0 36px 15px 0;background: url(../images/bg_logomark.png) no-repeat;
			background-size: cover;position: relative;
			img{opacity: 0;}
			&:before{
				content:"";position: absolute;top:0;left:0;width: 260px;height: 260px;opacity: 0;opacity: 0;
				background: url(../images/img_logomark01.png) no-repeat;background-size: cover;
			}	
		}
		.subtitle{font-size: 2.4rem;font-weight: bold;color: $BLACK;margin-bottom: 20px;}
		p{line-height: 2.0;}
		&.is-show{
			.image{
				img{opacity: 1;transition: opacity 1s $ease5 2.5s;}
				&:before{
					animation: logolineanime 1.5s $ease5 1.5s 1 normal;
				}
			}
		}
	}
}
@keyframes logolineanime{
	0%{opacity: 0;}
	50%{opacity: 1;}
	100%{opacity: 0;}
}

#services{
	.title{text-align: center;
		&:before{left:calc(50% - 30px);}
	}
	.subtitle{
		font-size: 2.0rem;line-height: 1;color: #fff;width: 350px;background: $BLUE;margin: 0 auto 35px;
		padding: 8px 0;font-weight: bold;text-align: center;
	}
	p{line-height: 2.0;margin-bottom: 0;}
	.service-list{
		display: flex;flex-wrap: wrap;
	}
	.service-box{
		width: 50%;padding: 38px 4%;position: relative;background-size: 150px !important;
		&:before{
			content:"";width: 100%;height: 1px;background: linear-gradient(90deg,$GRAD_START,$GRAD_END);
			position:absolute;top:0;left:0;
		}
		&:nth-child(2n+1){
			border-right: 1px solid $GRAD_END;
		}
		&:nth-child(2n){
			.subtitle{background: $PURPLE;}
		}
		&:nth-child(5),&:nth-child(6){
			&:after{
				content:"";width: 100%;height: 1px;background: linear-gradient(90deg,$GRAD_START,$GRAD_END);
				position:absolute;bottom:0;left:0;
			}
		}
		&:nth-child(1){background: url(../images/num_service01.png) 0 0 no-repeat;}
		&:nth-child(2){background: url(../images/num_service02.png) 0 0 no-repeat;}
		&:nth-child(3){background: url(../images/num_service03.png) 0 0 no-repeat;}
		&:nth-child(4){background: url(../images/num_service04.png) 0 0 no-repeat;}
		&:nth-child(5){background: url(../images/num_service05.png) 0 0 no-repeat;}
		&:nth-child(6){background: url(../images/num_service06.png) 0 0 no-repeat;}
	}
}


#staff{margin-bottom: 140px;overflow: hidden;
	.staff-box{
		width: 100%;position: relative;display: flex;
		&:before{
			content:"";width: 55%;height: 100%;position: absolute;top:0;z-index: 1;
		}
	}
	.title{text-align: center;margin-bottom: 110px;
		&:before{left:calc(50% - 30px);}
	}
	.image-box{
		width: 250px;position: relative;z-index: 2;top:-40px;
		.image{margin-bottom: 12px;}
		.image-border{
			width: 250px;height: 380px;position: absolute;z-index: 10;left:-10px;top:-10px;
			border-left: 1px solid $GRAD_START;border-right:1px solid $GRAD_END;
			&:before,&:after{
				content:"";width: 100%;height: 1px;background: linear-gradient(90deg,$GRAD_START,$GRAD_END);
				position: absolute;left:0;
			}
			&:before{top:0;}
			&:after{bottom:0;}
		}
	}
	.post{
		font-size: 1.3rem;font-weight: bold;color: $BLACK;margin-bottom: 10px;line-height: 1;
	}
	.subtitle{
		.en{@extend .roboto;font-style: italic;font-size: 1.3rem;display: inline-block;vertical-align: middle;letter-spacing: 0;}
		.jp{font-size: 2.4rem;font-weight: bold;color: $BLACK;display: inline-block;vertical-align: middle;margin-right: 15px;}
	}
	.profile{font-size: 1.4rem;position: relative;z-index: 3;padding-top: 35px;
		dt{width: 108px;float: left;}
		dd{padding-left: 108px;margin-bottom: 10px;}
	}
	.kawaguchi{
		padding-left: 100px;margin-bottom: 80px;
		&:before{
			left:-50px;transform: skew(-12deg);background:$BGC_BLUE;
		}
		.image-box{margin-right: 60px;}
		.subtitle .en{color: $BLUE;}
		&.anm{
			left:-40px;top:0;transition: opacity 1.2s $ease7 0.5s,left 1s $ease5 0.5s;
			&.is-show{left: 0;}
		}
	}
	.noguchi{
		padding-right: 100px;flex-direction: row-reverse;
		&:before{
			right:-50px;transform: skew(-12deg);background:$BGC_PURPLE;
		}
		.image-box{margin-left: 60px;}
		.subtitle .en{color: $PURPLE;}
		&.anm{
			right:-40px;transition: opacity 1.2s $ease7 0.5s,right 1s $ease5 0.5s;
			&.is-show{right: 0;}
		}
	}
}


#company{
	.company-box{
		width: 1000px;margin: 0 auto 100px;position: relative;
	}
	.title{
		width: 280px;position: absolute;right:0;top:0;
	}
	.company-tb{font-size: 1.4rem;width: 620px;
		tr{border-bottom: 1px solid $BDC;
			&:last-child{border-bottom: none;}
		}
		th,td{vertical-align: top;padding: 24px 0;}
		th{font-weight: bold;color: $BLACK;width: 115px;}
	}
	.access{
		width: 280px;position: absolute;right:0;bottom:0;border: 1px solid $BDC;font-size: 1.4rem;padding: 15px;
		h4{font-weight: bold;color: $BLACK;margin-bottom: 5px;}
		p{margin-bottom: 0;}
	}
	.gmap-wrap{
		height: 500px;position: relative;
	}
	#gmap{height: 100%;width: 100%;}
	#infowin{
		margin-bottom: 0;
		dt{font-weight: bold;color: $BLACK;}
		dd{font-size: 1.2rem;}
	}
	.gmap-link{
		display: inline-block;background: #fff;
		position: absolute;right:calc((100% - 1000px) / 2);bottom:0;
		a{display: block;color: $BLACK;font-weight: bold;font-size: 1.4rem;padding: 12px 15px 12px 35px;position: relative;line-height: 1;
			&:before{color: $BLUE;margin-right: 3px;position: absolute;left:12px;top:10px;font-size: 1.8rem;font-weight: normal;}
			&:hover{color: $BLUE;text-decoration: none;}
		}
	}
}

#contact{margin-bottom: 200px;
	.title{
		text-align: center;
		&:before{left:calc(50% - 30px);}
	}
	.lead{text-align: center;margin: 0 auto 80px;line-height: 2.0;}
	.contact-tb{font-size: 1.6rem;width:840px;margin: 0 auto 80px;
		th,td{vertical-align: top;padding: 35px 0 15px;}
		th{font-weight: bold;color: $BLACK;width: 265px;border-bottom: 1px solid $BDC;}
		td{border-bottom: 1px solid #ccc;border-left: 3px solid #fff;}
	}
	.require{
		float: right;font-size: 1.2rem;color: $RED;display: inline-block;margin: 3px 10px 0 0;
	}
	.btn-area{
		text-align: center;
	}
	#privacy-link{
		display: inline-block;color: $BLUE;cursor: pointer;
		&:hover{
			text-decoration: underline;
		}
	}
	.thanks-box{
		width: 600px;margin: 60px auto 0;padding: 60px 25px;text-align: center;
		border-top: none;border-bottom: none;border-left-color: $GRAD_START;border-right-color: $GRAD_END;position: relative;
		&:before,&:after{
			content:"";width: 100%;height: 1px;background: linear-gradient(90deg,$GRAD_START,$GRAD_END);
			position: absolute;left:0;}
		&:before{top:0;}
		&:after{bottom:0;}
		h4{font-size: 2.8rem;font-weight: bold;margin-bottom: 50px;color: $BLACK;}
		p{line-height: 2;}
	}
}
#privacy{
	opacity: 0;visibility: hidden;
	width: 100vw;height: 100vh;position: fixed;top:0;left:0;z-index: 999;background: rgba(0,0,0,0.6);
	padding-top: 15vh;overflow: hidden;transition:all 0.5s $ease1;
	&.is-show{
		opacity: 1;visibility: visible;
	}
	.privacy-close{
		position: absolute;right:10%;top:calc(15% - 75px);
	}
	.privacy-wrapper{
		background: #fff;width: 80%;margin: 0 auto;	border-radius: 4px;
	}
	.privacy-box{
		overflow-y: scroll;padding: 45px 60px;height: 70vh;
	}
	h3{
		font-size: 2.4rem;margin-bottom: 30px;color: $BLACK;font-weight: bold;
	}
	h4{font-size: 1.8rem;margin-bottom: 10px;padding-top: 15px;color: $BLACK;font-weight: bold;}
	h5{font-size: 1.6rem;margin-bottom: 10px;font-weight: bold;color: $BLACK;}
	p,ul{margin-bottom: 20px;line-height: 1.7;}
	ul li{margin-bottom: 10px;position: relative;padding-left: 20px;
		&:before{
			content:"";width: 5px;height: 5px;border: 1px solid $NAVY;border-radius:50% ;
			position: absolute;top:9px;left:5px;
		}
	}
}