//small pc
@media screen and (max-width: 1200px) {
	#gnavi{
		li{
			margin:0 12px;
		}
		.head-contact{
			a{padding: 0 15px;}
		}
	}
}