//form
input[type="button"],input[type="submit"]{
	-webkit-appearance: none; border: none; margin: 0; border-radius:0;cursor: pointer;font-family:$FONT_FAMILY;
}
input[type="text"],input[type="number"],input[type="password"],input[type="email"],textarea{
	-webkit-appearance: none; width:100%;	padding:5px 12px;border: none; border-radius:0;font-size: 1.6rem;
	background: transparent;font-family:$FONT_FAMILY;
}
:placeholder-shown {color: #bbb; }
::-webkit-input-placeholder {color:#bbb; }
::-moz-placeholder {color: #bbb; opacity: 1; }
:-ms-input-placeholder {color:#bbb; }

form{
	#sei,#mei,#seikana,#meikana{display: inline-block;width: 150px;margin-right:25px;}

	#tel,#fax{width: 280px;}
	#comment textarea{height: 300px;}
	.input-box{position: relative;
		&:before,&:after{
			content:"";display: block;width: 6px;height: 100%;border: 1px solid #ccc;
			position: absolute;top:-1px;
		}
		&:before{border-right: none;left:0;}
		&:after{border-left: none;right:0;}
	}
	.email-td .input-box{
		&:before,&:after{height: 34px;}
		&:first-child{margin-bottom: 18px;}
	}
	#submit{
		display:block; font-size: 2.1rem;color: #fff;width: 425px;margin: 0 auto 30px;padding: 25px 0;line-height: 1;
		background: linear-gradient(90deg,$GRAD_START 0%,$GRAD_END 50%,$GRAD_START 100%);transition: all 0.5s $ease7;
		background-size:200% 100%;background-position: 0 0;font-weight: bold;
		&:hover{
			background-position: 100% 0;box-shadow: 0 0 30px rgba(145,7,215,0.4);
		}
	}
	#back{
		display:block; font-size: 1.8rem;color: #fff;width: 200px;margin: 0 auto;padding: 20px 0;background: #666;line-height: 1;
		transition: all 0.5s $ease7;font-weight: bold;
		&:hover{
			opacity: 0.6;
		}
	}
}
